<template>
  <div class="field">
    <button class="button is-info" :class="buttonClass" @click="toggleOutliers">
      <span>Toggle outliers</span>
      <span class="icon"><i class="fal" :class="buttonIcon"/></span>
    </button>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'ExplorerOutlierToggle',
  computed: {
    ...mapState('explorer', ['displayOutliers']),
    buttonIcon() {
      return this.displayOutliers ? 'fa-eye-slash' : 'fa-eye'
    },
    buttonClass() {
      return this.displayOutliers ? 'is-light' : ''
    }
  },
  methods: {
    ...mapMutations('explorer', ['toggleOutliers'])
  }
}
</script>
